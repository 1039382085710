import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _37885f7c = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _53479d45 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _48fcdeb8 = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _2612c8d6 = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _1f8de254 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _a8646af6 = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _68c99b9c = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _386ed122 = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _7ac22504 = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _8e810fda = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _12a481cf = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _128fbee2 = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _440fe172 = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _0bd629c4 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _88008afe = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _f8c6c778 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _346c971c = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _ef943fc4 = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _634f3b77 = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _bc124610 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _1e3b649f = () => interopDefault(import('../pages/checkout/transsmart.vue' /* webpackChunkName: "pages/checkout/transsmart" */))
const _18cb4f58 = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _ba694c92 = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _e7d01524 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _52bfca1d = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _1ecb21b4 = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _5ad692c1 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _3c54e15f = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _2e2168a3 = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _f7634f12 = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _392e9ce1 = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _08fb8fcc = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _5607a4c7 = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _76b4983e = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _68c9516e = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _c17821b2 = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _2d57d414 = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _1ba2334e = () => interopDefault(import('../pages/review/_slug.vue' /* webpackChunkName: "pages/review/_slug" */))
const _0fd3fd9e = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _43ac5b96 = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _03abb630 = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _0ad49778 = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _34ef49c4 = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _401020b2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f6734198 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _37885f7c,
    children: [{
      path: "",
      component: _53479d45,
      name: "account___nl"
    }, {
      path: "details",
      component: _48fcdeb8,
      name: "account-details___nl"
    }, {
      path: "newsletter",
      component: _2612c8d6,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _1f8de254,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _a8646af6,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _68c99b9c,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _386ed122,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _7ac22504,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _8e810fda,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _12a481cf,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _128fbee2,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _440fe172,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/cart",
    component: _0bd629c4,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _88008afe,
    children: [{
      path: "",
      component: _f8c6c778,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _346c971c,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _ef943fc4,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _634f3b77,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _bc124610,
      name: "checkout-payment___nl"
    }, {
      path: "transsmart",
      component: _1e3b649f,
      name: "checkout-transsmart___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _18cb4f58,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _ba694c92,
    name: "checkout-success___nl"
  }, {
    path: "/faq",
    component: _e7d01524,
    name: "faq___nl"
  }, {
    path: "/forgot-password",
    component: _52bfca1d,
    name: "forgot-password___nl"
  }, {
    path: "/icon",
    component: _1ecb21b4,
    name: "icon___nl"
  }, {
    path: "/login",
    component: _5ad692c1,
    name: "login___nl"
  }, {
    path: "/merken",
    component: _3c54e15f,
    name: "brand___nl"
  }, {
    path: "/nieuws",
    component: _2e2168a3,
    name: "blog-category___nl"
  }, {
    path: "/vestigingen",
    component: _f7634f12,
    name: "store-lister___nl"
  }, {
    path: "/blocks/demo",
    component: _392e9ce1,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _08fb8fcc,
    name: "forgot-password-reset___nl"
  }, {
    path: "/login/guest",
    component: _5607a4c7,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _76b4983e,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/nieuws/detail/:slug?",
    component: _68c9516e,
    name: "blog-detail-slug___nl"
  }, {
    path: "/product/detail/:slug?",
    component: _c17821b2,
    name: "product-detail-slug___nl"
  }, {
    path: "/product/lister/*",
    component: _2d57d414,
    name: "product-lister-all___nl"
  }, {
    path: "/review/:slug?",
    component: _1ba2334e,
    name: "review-slug___nl"
  }, {
    path: "/search/:slug?",
    component: _0fd3fd9e,
    name: "search-slug___nl"
  }, {
    path: "/winkel/:slug?",
    component: _43ac5b96,
    name: "store-detail-slug___nl"
  }, {
    path: "/nieuws/*",
    component: _03abb630,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _0ad49778,
    name: "blog-tag-all___nl"
  }, {
    path: "/merken/*",
    component: _34ef49c4,
    name: "brand-lister-all___nl"
  }, {
    path: "/",
    component: _401020b2,
    name: "index___nl"
  }, {
    path: "/*",
    component: _f6734198,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
